import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilCalendar,
  cilPencil,
  cilTrash,
  cilExternalLink,
  cilZoom,
  cilAddressBook,
  cilPlus,
  cilCalculator,
  cilFile,
  cilChevronBottom,
  cilChevronTop,
  cilPaperPlane,
  cilSpreadsheet,
  cilDescription,
  cilBarcode,
  cilBellExclamation,
  cilFindInPage,
  cilWarning,
  cilOptions,
  cilFolderOpen,
  cilEyedropper,
  cilUser,
  cilTask,
  cilSave,
  cilBook,
  cilViewQuilt,
  cilBarChart,
  cilInfo,
  cilCheckCircle
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilCalendar,
    cilPencil,
    cilTrash,
    cilExternalLink,
    cilZoom,
    cilAddressBook,
    cilPlus,
    cilCalculator,
    cilFile,
    cilChevronBottom,
    cilChevronTop,
    cilPaperPlane,
    cilSpreadsheet,
    cilDescription,
    cilBarcode,
    cilBellExclamation,
    cilFindInPage,
    cilWarning,
    cilOptions,
    cilFolderOpen,
    cilEyedropper,
    cilUser,
    cilTask,
    cilSave,
    cilBook,
    cilViewQuilt,
    cilBarChart,
    cilInfo,
    cilCheckCircle
  }
);
